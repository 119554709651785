import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import Catherine from '../../images/directors2.jpg'
import BG from '../../images/awards-bg.jpg'

class CatherineSimons extends React.Component {
	render() {
		const siteTitle = 'Catherine Simons'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div class="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Catherine Simons</h3>
								<div className="row">
									<div className="col-12">
										<p>
											Catherine Simons is a Director and the Chief Operating
											Officer of WSC Group. Catherine works with her clients to
											assist them build more profitable businesses and create
											more personal wealth through business advisory and
											taxation services. Catherine’s focus is to achieve the
											best outcome for her client every time.
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<p>
											Catherine’s clients include small to medium sized
											businesses, self-managed superfunds and rental property
											investors at all stages of their investment journey.
										</p>
										<p>
											Catherine has over 13 years’ experience in public practice
											accounting with a Degree in Commerce (Accounting Major
											with the University of Wollongong). She is also a
											Certified Practicing Accountant (CPA) and a registered tax
											agent.
										</p>
									</div>
									<div className="col-lg-6 col-md-12">
										<img
											src={Catherine}
											className="img-fluid"
											alt="Catherine Simons"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<p>
											Catherine also manages the continuous improvement and
											internal business process within WSC Group. This gives her
											the practical experience in running a business on a day to
											day basis and also the knowledge to assist her small to
											medium sized business clients.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default CatherineSimons

export const CatherineSimonsPageQuery = graphql`
	query CatherineSimonsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
